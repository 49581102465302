#header_holder {
  background-color: #26a2f0;
  /* box-shadow: 0 1px 6px 2px #26a2f033; */
  height: 64px;
  padding: 1px;
  z-index: 10;
}

#header_center {
  margin: 0 auto;
  max-width: 1280px;
}

#mjn_logo {
  float: left;
  margin: 4px 24px;
}

#header_menu {
  list-style-type: none;
  margin: 0;
}

.header-item {
  float: left;
  margin: 22px 12px 0;
}

.header-item a {
  color: #fafafa;
  font-weight: bold;
  padding: 0 12px 21px;
  text-decoration: none;
}

.header-item a:hover {
  color: #ffffff;
}

.header-item a.selected {
  border-bottom: 2px solid #ffffff;
  color: #ffffff;
}

a.menu-list-item {
  color: #333333;
  text-decoration: none;
}

.koubara {
  background-color: #fafafa;
  border-radius: 10px;
  color: #26a2f0 !important;
  margin-top: 3px !important;
  padding-top: 0;
}

@media (max-width: 800px) {
  #header_holder {
    position: fixed;
    top: 0;
    width: 100%;
  }

  #header_center {
    display: flex;
  }

  #header_center .logo-link {
    margin: 0 auto;
  }

  #header_menu {
    position: fixed;
    right: 0;
  }
}
