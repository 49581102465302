input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.skills-table {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #555555;
}

.skills-table span:nth-child(2) {
  white-space: nowrap;
}

.skills-button {
  color: #49A2F0;
  border: none;
  background: none;
  outline: none;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
