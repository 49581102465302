.rce-mbox-right {
  background-color: #26a2f0;
  color: #ffffff;
}

.rce-mbox {
  max-width: 65%;
}

.rce-mlist {
  margin-top: 8px;
}

.message-holder {
  /* height: calc(100vh - 420px); */
  height: calc(100vh - 380px);
  overflow-y: auto;
}

.chat-list {
  /* height: calc(100vh - 410px); */
  height: calc(100vh - 370px);
  overflow-y: auto;
}

.message-list {
  /* height: calc(100vh - 488px); */
  height: calc(100vh - 448px);
  overflow-y: auto;
}

.message-list-interview {
  /* height: calc(100vh - 538px); */
  height: calc(100vh - 508px);
  overflow-y: auto;
}

.chat-list {
  border-right: 1px solid #cccccc;
}

.chat-list.singlecompany {
  height: calc(100vh - 201px);
}

.message-list.singlecompany {
  height: calc(100vh - 270px);
}

.message-list-interview.singlecompany {
  height: calc(100vh - 320px);
}

.message-holder.singlecompany {
  height: calc(100vh - 201px);
}

.rce-container-input {
  border: 1px solid #cccccc;
  font-family: 'Ubuntu', sans-serif;
  margin-top: 12px;
  min-width: auto;
  width: 100%;
}

.rce-input-textarea {
  font-family: 'Ubuntu', sans-serif !important;
}

.rce-mbox-status svg {
  color: #ffffff !important;
  font-weight: bold;
}

img.rce-avatar {
  object-fit: cover;
}

.rce-mbox-right .rce-mbox-title {
  color: #dddddd;
}

.rce-mbox-time {
  min-width: 160px;
}

@media (max-width: 1390px) {
  .rce-container-input {
    margin-top: 12px;
    min-width: auto;
    width: calc(100% - 80px);
  }
}

@media (max-width: 800px) {
  .chat-list {
    border: hidden;
    margin-bottom: 32px;
  }

  .chat-list.singlecompany {
    height: calc(100vh - 220px);
  }

  .rce-container-input {
    margin-top: 12px;
    min-width: auto;
    width: 100%;
  }

  .message-holder {
    height: calc(100vh - 190px);
    overflow-y: auto;
  }

  .message-list {
    height: calc(100vh - 250px);
    overflow-y: auto;
  }

  .message-list-interview {
    height: calc(100vh - 310px);
  }
}
