body {
  background-color: #e9eff9 !important;
  font-family: 'Ubuntu', sans-serif !important;
  line-height: normal !important;
  margin: 0;
  padding: 0;
}

.clearfix {
  clear: both;
}

.jss131 {
  flex: 0 0 auto;
  margin: 0;
  padding: 24px 24px 20px;
}

/* stylelint-disable */
.css-m5vj9m-MuiStepper-root {
  padding: 24px 16px;
}
/* stylelint-enable */

#main_holder {
  margin: 0 auto;
  max-width: 1280px;
  padding: 24px;
  position: relative;
  /* min-height: 1000px; */
}

#trialnotice {
  background-color: #ffecb3;
  border: 0;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 8px;
  padding: 12px;
  text-align: center;
  width: 100%;
}

h1 {
  margin-bottom: 12px;
  margin-top: 0;
}

.terms {
  bottom: 0;
  left: 7%;
  position: fixed;
  width: 38.5%;
}

.terms p {
  font-size: 12px;
}

.terms a {
  color: #26a2f0;
  font-weight: bold;
  text-decoration: underline;
}

#crisp-container div.crisp-app iframe.crisp-launcher-frame-mobile {
  bottom: 64px !important;
}

#company_switcher {
  /* padding-top: 12px;
  padding-bottom: 16px; */
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.company-switcher-bubble {
  cursor: pointer;
  /* width: 53px; */
  margin-bottom: 5px;
  margin-right: 2px;
  width: 96px;
}

.company-switcher-photo-holder {
  border: 3px solid #dddddd;
  border-radius: 50%;
  padding: 3px;
}

.company-switcher-default {
  border: 3px solid #26a2f0;
}

.company-switcher-unread {
  border: 3px solid #f50057;
}

.company-switcher-photo-holder .nojobs img {
  filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

div.company-switcher-photo {
  height: 84px;
  width: 84px;
  /* width: 42px;
  height: 42px; */
}

.company-switcher-name {
  color: #777777;
  font-size: 12px;
  line-height: 1.5em;
  margin-top: 6px;
  max-height: 72px;
  overflow: hidden;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .company-switcher-name {
    max-height: 36px;
  }
}

.company-switcher-name-white {
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 4px;
}

button.new-job-button {
  float: right;
  margin-bottom: 24px;
}

.expand-application-count {
  background: none;
  border: 0;
  color: #26a2f0;
  font-size: 15px;
  font-weight: bold;
  padding: 0 24px 0 0;
}

.activate-job {
  float: right;
  margin-top: -12px;
}

.application-details {
  overflow-x: hidden;
}

.applications-row {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.applicant-card {
  flex: 1;
  max-width: 300px;
  min-width: 250px;
}

.application-details-header {
  align-content: center;
  display: flex;
  justify-content: space-between;
}

.application-details-header h2 {
  font-size: 20px;
}

.application-details-header p {
  margin: 5px 0;
}

h2.application-details-name {
  margin: 5px 0;
}

div.application-details-photo {
  height: 100px;
  width: 100px;
}

.few-words {
  border-bottom: 1px solid #cccccc;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 18px;
  padding-bottom: 14px;
}

.application-details-experience-wrapper {
  border-bottom: 1px solid #cccccc;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 18px;
  margin-bottom: 14px;
  padding-bottom: 14px;
}

.application-details h3 {
  font-size: 18px;
}

.application-details-experience-wrapper h3 {
  font-size: 18px;
}

.application-separator {
  border-bottom: 1px solid #cccccc;
  margin: 14px auto 28px;
  padding-bottom: 14px;
  width: 40%;
}

.application-separator-long {
  border-bottom: 1px solid #cccccc;
  margin: 14px auto 28px;
  padding-bottom: 14px;
}

.expiring {
  margin-bottom: 12px;
  padding: 8px;
}

.warning-badge {
  cursor: pointer !important;
  margin-left: 16px;
}

.application-details-dates,
.application-details-company-skill {
  display: flex;
  justify-content: space-between;
}

.application-details-company-skill {
  margin: 6px 0;
}

.application-details-start-end {
  color: #757575;
}

.application-details-duration {
  color: #26a2f0;
  font-weight: bold;
}

.application-details-company {
  font-weight: bold;
}

.application-details-experience-bubble {
  margin-right: 10px;
}

div.application-details-next {
  font-size: 14px;
  margin: 24px 0 0;
  text-align: center;
}

.step-holder {
  margin-bottom: 32px;
  padding: 24px;
}

.add-company-card {
  min-height: 500px;
  padding: 24px;
  position: relative;
}

.company-search {
  width: 80%;
}

.custom-wrapper {
  font-size: 20px;
  line-height: 1.5;
}

#onboard_instructions {
  font-size: 14px;
  margin: 48px 16px 24px 0;
}

#photo_holder {
  background-color: #26a2f0;
  border: 0;
  cursor: pointer;
  height: 100%;
  position: relative;
  text-align: center;
}

#photo_holder:hover {
  background-color: #0781cc;
}

#companyphotoupload {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}

#companyphotoupload + label {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
}

#companyphotoupload + label {
  cursor: pointer;
  /* "hand" cursor */
}

#add_photo_icon {
  font-size: 48px;
  margin-top: 24px;
}

.company-preview {
  margin-bottom: 24px;
  width: 300px;
}

#pricing_holder {
  display: flex;
  justify-content: center;
  margin: 24px auto 10px;
  max-width: 920px;
}

.pricingName {
  margin: 0;
}

.pricing-plan-card {
  cursor: pointer;
  margin: 1%;
  margin-bottom: 48px;
  margin-top: 48px;
  min-height: 300px;
  text-align: center;
  width: 30%;
}

.pricing-plan-selected {
  border: 5px solid #26a2f0;
}

.pricing-price {
  color: #26a2f0;
  font-size: 64px;
  margin: 16px 0 0;
}

button.pricing-button {
  margin: 0 auto;
  padding: 12px 36px 9px;
  border-radius: 8px;
  font-size: 15px;
  box-shadow: none;
  height: 41px;
}

.pricing-features {
  display: block;
  text-align: center;
  color: #9F9F9F;
  font-size: 16px;
  margin: 24px auto 12px;
  width: 100%;
}

.pricing-discount {
  color: #f50057;
  font-weight: bold;
}

.refer_friend {
  margin: 0 15px 10px;
}

.refer_friend_title {
  display: flex;
  justify-content: center;
}

.refer_friend .gift-icon {
  top: 897px;
  left: 159px;
  width: 27px;
  /* UI Properties */
  text-align: left;
  margin-top: -5px;
  margin-right: 5px;
  font: normal normal normal 34px/39px Ubuntu;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
}

@media (max-width: 940px) {
  .refer_friend_title {
    display: block;
  }

  .refer_friend .gift-icon {
    width: 42px;
  }
}

#bt_holder_outer,
#invoice_details {
  margin: 48px 24px;
  width: calc(100% - 48px);
}

#start_subscription_button {
  margin-left: 24px;
}

.sub-disclaimer {
  font-size: 12px;
  margin-left: 24px;
}

#invoice_step_two {
  display: none;
}

div.snackred {
  background-color: #f44336;
}

div.snackgreen {
  background-color: #4caf50;
}

div.warning {
  background-color: #ffecb3;
}

div.alert {
  background-color: #ef9a9a;
}

.arrow-box {
  background: #26a2f0;
  margin-right: 36px;
  position: relative;
}

.arrow-box::after,
.arrow-box::before {
  border: solid transparent;
  content: ' ';
  height: 0;
  pointer-events: none;
  position: absolute;
  right: -20px;
  top: 45%;
  transform: rotate(-90deg);
  width: 0;
}

.arrow-box::after {
  border-color: rgba(38, 162, 240, 0);
  border-top-color: #26a2f0;
  border-width: 10px;
  margin-left: -10px;
}

.arrow-box::before {
  border-color: rgba(194, 225, 245, 0);
  border-width: 16px;
  margin-left: -16px;
}

#catch_click {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

/* stylelint-disable */
ul.react-datepicker__time-list {
  padding: 0;
}

.react-datepicker__header--time {
  height: 90px;
}

.react-datepicker__time-container
div.react-datepicker__time
div.react-datepicker__time-box
ul.react-datepicker__time-list {
  height: 279px;
}
/* stylelint-enable */

div.react-datepicker {
  min-height: 550px;
}

.required-fields {
  border-right: rgba(0, 0, 0, 0.2) 0.5px solid;
  display: flex;
  flex-direction: column;
  min-width: 426px;
  padding-right: 12px;
  width: 50%;
}

.optional-fields {
  border-left: rgba(0, 0, 0, 0.2) 0.5px solid;
  display: flex;
  flex-direction: column;
  min-width: 426px;
  padding-left: 12px;
  width: 50%;
}

#closed_jobs .closed-title {
  margin: 60px 0 20px;
}

.expand-edit-job {
  border: 0;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .required-fields {
    border-right: hidden;
    margin-bottom: 40px;
    min-width: unset;
    padding-right: 0;
    width: 100%;
  }

  .optional-fields {
    border-left: hidden;
    margin-bottom: 40px;
    min-width: unset;
    padding-left: 0;
    width: 100%;
  }
}

/* MOBILE */

@media (max-width: 800px) {
  #main_holder {
    margin-bottom: 64px;
    margin-top: 64px;
    padding: 4px;
  }

  h1 {
    margin-bottom: 12px;
    text-align: center;
  }

  button.new-job-button {
    display: flex;
    float: none;
    margin: 24px auto;
  }

  #applications_container {
    max-width: 100%;
    /* overflow: hidden; */
  }

  .skill-badge span {
    max-width: 180px;
    overflow: hidden;
  }

  /* stylelint-disable */
  div.expansionPanelSummary {
    padding: 0 4px;
  }
  /* stylelint-enable */

  .expand-application-count {
    align-items: center;
    display: flex;
    padding: 1px 12px 0 0;
  }

  #closed_jobs {
    margin-top: 64px;
  }

  .activate-job {
    float: none;
    margin-top: inherit;
  }

  div.applications-row-holder {
    padding-left: 5px;
    padding-right: 5px;
  }

  .applications-row {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .applicant-card {
    margin-right: 8px;
  }

  div.application-details-photo {
    height: 75px;
    margin: 0 auto;
    width: 75px;
  }

  h2.application-details-name {
    font-size: 16px;
    margin: 16px 8px;
    width: 150px;
  }

  video {
    max-height: 200px !important;
  }

  .application-details-experience {
    list-style-type: none;
    padding-left: 0;
  }

  .application-details-experience-bubble {
    margin-right: 200px;
  }

  .step-holder {
    margin-bottom: 32px;
    padding: 4px;
  }

  #company_find {
    margin-top: 16px;
  }

  #company_find h2 {
    font-size: 21px;
    margin: 8px;
  }

  #stepper {
    padding: 8px;
  }

  .add-company-card {
    min-height: 400px;
    padding: 8px;
    position: relative;
  }

  .company-search {
    margin-left: 8px;
    margin-top: 16px;
    width: 90%;
  }

  #companyphotoupload + label {
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
  }

  #add_photo_icon {
    font-size: 36px;
    margin-top: 12px;
  }

  .company-preview {
    margin-bottom: 24px;
    width: 100%;
  }

  .company-preview h2 {
    margin-left: 0 !important;
  }

  .company-switcher-bubble {
    cursor: pointer;
    margin-right: 10px;
    width: 76px;
  }

  div.company-switcher-photo {
    height: 64px;
    width: 64px;
  }

  div.billing-small {
    margin-left: 0;
    width: 100%;
  }

  #start_subscription_button {
    display: flex;
    margin: 0 auto 48px;
  }

  #pricing_holder {
    display: initial;
  }

  .pricing-plan-card {
    margin: 24px auto;
    width: 95%;
  }

  #company_switcher {
    padding-top: 0;
  }

  #trialnotice {
    font-size: 14px;
    padding: 12px;
  }
}

@media (max-width: 330px) {
  .skill-badge span {
    max-width: 136px;
    overflow: hidden;
  }
  /* div.expandEditJob {
    padding-right: 48px !important;
  } */
  h2.application-details-name {
    width: 115px;
  }
}

h1 {
  color: #555555;
  margin: 12px 0;
}

h2 {
  color: #555555;
}

.form-title {
  margin-bottom: 24px !important;
}

.wrapper-fields {
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
}

.group-title {
  font-weight: 700 !important;
  margin-bottom: 12px !important;
}

/* stylelint-disable */
.formSelectGroup {
  margin-right: 32px;
  min-width: 220px;
}

.formGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.formSelect {
  min-width: 123px;
  /* margin-right: 32px; */
}
/* stylelint-enable */

.data-hj-whitelist {
  margin-bottom: 40px !important;
}

.header-item {
  margin: 0 !important;
  padding: 22px 12px 0;
}

.header-item:first-child {
  margin-left: 8px !important;
}

.header-item:last-child {
  margin-left: 8px !important;
}

.header-item a {
  display: block;
}

.header-item:hover {
  background-color: #4ab1f2;
}

.koubara {
  margin-left: 8px !important;
}

.koubara:hover {
  background-color: #ffffff !important;
}

.new-job-form {
  max-width: 1200px !important;
  width: 90vw !important;
}

.margin-right-ten {
  margin-right: 10px !important;
}

/* stylelint-disable */
.formControl {
  width: 100%;
}
/* stylelint-enable */

.select-fixer {
  margin-bottom: 8px !important;
  margin-top: 16px !important;
}

.age-group {
  margin-right: 8px !important;
  width: 100px;
}

.age-group-last {
  margin-right: 32px !important;
  width: 100px;
}

.euro-icon::after {
  content: '€';
  position: absolute;
  right: 10px;
  top: 22px;
}

@media screen and (max-width: 1000px) {
  /* stylelint-disable */
  .formGroup {
    justify-content: flex-start;
  }
  /* stylelint-enable */
}

@media screen and (max-width: 800px) {
  .mobile-holder-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 20px;
    width: 100vw;
    z-index: 99999;
  }

  .mobile-holder-wrapper a {
    text-decoration: none;
  }

  .mobile-holder-wrapper h2 {
    text-align: center;
  }

  .download-btn {
    background-color: #26a2f0;
    border: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 40px;
    padding: 1rem 4rem;
    transition: 0.35s;
  }

  .download-btn:hover {
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.2);
  }

  .mobile-bottom {
    color: #757575;
    text-align: center;
  }
}

.terms {
  bottom: 0;
  left: 7%;
  position: fixed;
  width: 40%;
}

#firebase_cont {
  bottom: 10%;
  display: flex;
  justify-content: center;
  left: 7%;
  min-width: 320px;
  position: fixed;
  width: 40%;
}

#sign_container {
  display: flex;
}

#sign_container::before {
  background-color: #ffffff;
  content: '';
  height: 100vh;
  left: 7%;
  min-width: 320px;
  position: fixed;
  top: 0;
  width: 40%;
  z-index: 0;
}

.sign-up-text {
  left: 7%;
  min-width: 320px;
  padding: 0 15px;
  position: fixed;
  top: 10%;
  width: 40%;
}

.logo-sign {
  align-items: center;
  display: flex;
  height: calc(100vh - 188px);
  justify-content: flex-end;
  width: 100vw;
}

.logo-sign-text {
  max-width: 45%;
  padding-left: 20px;
  text-align: left;
}

.sign-up-text img {
  width: 175px;
}

.logo-sign-text h1 {
  color: #555555;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  margin: 16px 0;
  margin-bottom: 40px;
}

.logo-sign-text ul {
  margin-bottom: 20px;
  padding: 0;
}

.logo-sign-text ul li {
  color: #424242;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  list-style: inside;
}

@media screen and (max-width: 1280px) {
  .terms {
    bottom: 0;
    width: 45%;
  }

  #firebase_cont,
  .sign-up-text,
  #sign_container::before {
    width: 45%;
  }
}

@media screen and (max-width: 860px) {
  .terms {
    width: 80%;
  }

  #firebase_cont,
  .sign-up-text,
  #sign_container::before {
    width: 80%;
  }

  .logo-sign-text {
    display: none;
  }

  .up-sign-text {
    display: none;
  }

  #firebase_cont {
    bottom: 10%;
  }
}

@media screen and (max-width: 380px) {
  .terms {
    left: 0;
    width: 100%;
  }

  #firebase_cont,
  .sign-up-text,
  #sign_container::before {
    left: 0;
    width: 100%;
  }

  #firebase_cont {
    bottom: 10%;
  }

  .sign-up-text {
    top: 5%;
  }
}

.main-jobs-container {
  display: flex;
}

#company_switcher {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  margin-right: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
  position: fixed;
  width: 120px;
}

.fixed-fixer {
  margin-left: 140px;
}

#interview_holder table {
  margin-top: 32px;
}

#interview_holder thead {
  background-color: #efefef;
}

#interview_holder thead th {
  color: #000000;
  font-weight: bold;
}

#interview_holder thead tr {
  /* max-height: 24px; */
  height: 32px;
}

#interview_holder .intleft {
  padding-right: 24px;
  width: 116px;
}

#interview_holder .intright {
  padding-left: 0;
  padding-right: 0;
}

#interview_holder .tactions {
  width: 150px;
}

.candidate-holder {
  cursor: pointer;
}

.intaccepted.intleft {
  border-right: 2px solid #4caf50;
}

.intright div {
  align-items: center;
  display: flex;
  margin-left: 16px;
}

.intrejected {
  text-decoration: line-through;
}

.intrejected.intleft {
  border-right: 2px solid #f44336;
}

.intpending.intleft {
  border-right: 2px solid #ffc107;
}

.intpending.intright {
  background: repeating-linear-gradient(-55deg,
  #fff3cd,
  #fafafa 10px,
  #fff3cd 10px,
  #fafafa 10px);
}

.intrejected.intright {
  background: repeating-linear-gradient(-55deg,
  #fdf1f0,
  #fafafa 10px,
  #fdf1f0 10px,
  #fafafa 10px);
}

.intpending .candidate-holder,
.intrejected .candidate-holder {
  color: #888888;
}

.intavatar {
  margin-right: 16px;
}

@media screen and (max-width: 800px) {
  .main-jobs-container {
    display: flex;
    flex-direction: column;
  }

  #company_switcher {
    flex-direction: row;
    height: auto;
    margin-right: 0;
    max-height: 170px;
    min-width: auto;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
  }

  .company-search {
    display: none;
  }

  .arrow-box::after,
  .arrow-box::before {
    left: 50%;
    right: unset;
    top: 100%;
    transform: unset;
  }

  .company-switcher-bubble {
    margin-bottom: 15px;
  }

  .fixed-fixer {
    margin-left: 0;
  }
}

.salary-visible {
  margin-top: -20px;
}

.firebaseui-container {
  width: 330px !important;
}

/* stylelint-disable */
.lang-group .formGroup p {
  color: #555555;
  font-size: 13px;
}

.expansionPanel {
  margin: 0 0 20px;
}
/* stylelint-enable */

.main-app-container {
  justify-content: space-between;
  padding: 0 10px !important;
}

@media screen and (max-width: 800px) {
  .salary-visible {
    margin-top: 0;
  }
}

.special-form-group {
  line-height: 39px !important;
  min-width: 178px !important;
}

.experience-years {
  margin-top: -19px !important;
}

@media screen and (max-width: 620px) {
  .experience-years {
    margin-top: 0 !important;
  }

  .age-group {
    margin-bottom: 8px !important;
  }

  .age-group-last {
    margin-bottom: 8px !important;
  }

  .margin-right-ten {
    margin-bottom: 8px !important;
  }

  .optional-margin {
    margin: 16px 0 8px !important;
  }

  .distance-margin {
    margin-top: 32px !important;
  }
}
